import React from 'react'  
import { CSVLink } from "react-csv";
import moment from "moment";

function LoanTable(props) { 
 
    const headers = [
        { label: "Dealership Name", key: "loan_id" },
        { label: "Incentive Type", key: "scheme_type" },
        { label: "Loan ID", key: "loan_id" },
        { label: "Customer Name", key: "customer_name" },
        { label: "Disbursed Date", key: "disbursed_date" },
        { label: "Disbursed Date Type", key: "disbursed_type" },
        { label: "Loan Value", key: "amount" },
        { label: "Loan Amount Pass Through", key: "loan_amount_pass_through" },
        { label: "ADP", key: "adp_type" },
        { label: "So Name/Agent Name", key: "so_name" },
        { label: "Business Line", key: "business_line" } 
    ];   
    return (
        <>
            <tr>
                <td colSpan={(props.pageType===1)?11:12}className='br-topNone loan-tr'>
                    <table className="loan-table">
                        <thead>
                            <tr>
                                <th>Loan ID</th>
                                <th>Customer Name</th>
                                <th>Disbursed Date</th>
                                <th>Disbursed <br /> Date Type</th>
                                <th>Loan Value</th>
                                <th>Loan Amount <br /> Pass Through </th>
                                <th>ADP </th>
                                <th>SO Name /<br/>Agent Name </th>
                                <th>Business Line </th>
                                <th> 
                                    <CSVLink data={props.list} headers={headers} filename={"loan-list.csv"}>
                                        <button className='btn-line'>Export</button>
                                    </CSVLink>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                props.list.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{item.loan_id}</td>
                                            <td>{item.customer_name}</td>
                                            <td>{moment(item.disbursed_date).format('D MMM, YYYY')}</td>
                                            <td>{item.disbursed_type}</td>
                                            <td>{item.amount}</td>
                                            <td>{item.loan_amount_pass_through}</td>
                                            <td>{item.adp_type}  </td>
                                            <td>{item.so_name} </td>
                                            <td>{item.business_line} </td>
                                            <td> 

                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </td>
            </tr>

        </>
    )
}

export default LoanTable