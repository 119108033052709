import moment from 'moment';
import Pagination from '../common/Pagination';
import { toast } from 'react-toastify';
import DealerHistory from "./DealerHistory";
import * as HELPER from '../../config/helper';
import React, { useEffect, useState } from "react";
import ConfirmationModal from "../common/ConfirmationModal";
import CurrencyInputField from "../common/CurrencyInputField"; 
import { UPDATE_DIFF_AMOUNT,UPDATE_STATUS,GET_PAID_URL } from "../../services/dealerdata.gql";
import FullPageLoader from "../common/FullPageLoader";
import { executeGraphQLMutation,executeGraphQLQuery } from "../../common/executeGraphQLQuery";
import { useApolloClient } from '@apollo/client';
import { useSelector} from "react-redux";
import dealerPaidIcon from "../../webroot/images/dealer_paid_icon.png";
import downloadIcon from "../../webroot/images/downloadicon.svg";
import LoanTable from './LoanTable'

const DealerListTable = ({fetchListingData, data,showApproveRejectButton, setSelectedDealerRecord,selectedDealerIdRecord,pageType,removeSelectAll,setRemoveSelectAll,setSelectAll}) => {
    const [popBoxData, setPopBoxData] = useState({
        status: false,
        msg: "",
    });

    // Define state to handle the value change for each CurrencyInputField
    const [actionData, setActionData] = useState({});
    const [tableData, setTableData] = useState([]);
    const [filterData, setFilterData] = useState({});
    const [viewTimeLine, setViewTimeLine] = useState(false);
    const [paginationData, setPaginationData] = useState({});
    const [multiSelectedDealers, setMultiSelectedDealer] = useState([]);
    const [checkedAll, setCheckedAll] = useState(false);
    const [selectAllDisabled, setSelectAllDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showInputSave, setShowInputSave] = useState({ loanId: 0, key: "" });
    const [trShow, setTrShow] = useState(false);
    const [trShowData, setTrShowData] = useState({user_id:'',type_id:'',idArr:[]});
    const client = useApolloClient();
    const [tabelShow, setTabelShow] = useState(false);
    const [userLoanDetail, setUserLoanDetail] = useState(false);

    const USER_DATA = JSON.parse(localStorage.getItem("user_information")); 
    const dealerDataState = useSelector((state) => state.dealerdata);

    useEffect(() => {
        if (removeSelectAll) {
            setMultiSelectedDealer([]);
            setSelectedDealerRecord({});
            setCheckedAll(false);
            setRemoveSelectAll(false)
        }
    }, [removeSelectAll])
   
    const selectAll = (e) => {
        let selectedId = []  
        if(tableData.length){
            selectedId = tableData.reduce((acc, user) => { 
                return acc.concat(user.user_id);
              }, []);
        } 
        // if(selectedId.length){
            if (e.target.checked) {
                setMultiSelectedDealer(selectedId);
                setSelectedDealerRecord({ 
                    ...selectedDealerIdRecord,
                    dealer_ids: selectedId,
                    incentive_month: tableData?.[0]?.items?.[0]?.incentive_month
                 });
                setCheckedAll(true);
                setSelectAll(true);
                showApproveRejectButton(true);
            } else {
                setMultiSelectedDealer([]);
                setSelectedDealerRecord({});
                setCheckedAll(false);
                showApproveRejectButton(false);
            }
        // }else{
        //     setMultiSelectedDealer([]);
        //     setSelectedDealerRecord({});
        //     setCheckedAll(false);
        //     showApproveRejectButton(false);
        // }
        
    };

   
    const handleSingleCheckboxChange = async (e, list) => { 
        let getMultiSelectedDealers = multiSelectedDealers;
        if (e.target.checked) {
            if (
                multiSelectedDealers.length + 1 ===
                tableData.length
            ) {
                setCheckedAll(true); 
             }
             getMultiSelectedDealers = [...getMultiSelectedDealers, list.user_id]
             setMultiSelectedDealer(getMultiSelectedDealers);
             setSelectedDealerRecord({ 
                ...selectedDealerIdRecord,
                dealer_ids: getMultiSelectedDealers,
                incentive_month: list?.items?.[0]?.incentive_month
             }); 
            showApproveRejectButton(true);
        } else {
            setCheckedAll(false);
            let newSelctedId = multiSelectedDealers.filter((ob) => +ob !== +list.user_id); 
            getMultiSelectedDealers = newSelctedId

            setMultiSelectedDealer(getMultiSelectedDealers);
            setSelectedDealerRecord({ 
                ...selectedDealerIdRecord,
                dealer_ids: getMultiSelectedDealers,
                incentive_month: list?.items?.[0]?.incentive_month
             }); 

            let status = newSelctedId.length ? true : false;
            showApproveRejectButton(status);
        } 
 
    };

    const handleInputChange = (name,key,user_id,id, event) => {
        let val = HELPER.removeCurrencyFormatting(event.target.value);
        let tableDataArr = JSON.parse(JSON.stringify(tableData));
        //UPDATE VALUE IN STATE

        for (let data of tableDataArr) {
            if(data.user_id === +user_id){
                for (let row of data.items) {
                    if (+row.id === +id) {
                        row[name] = val;
                    }
                }
            }
        }
        setTableData(tableDataArr);
        setShowInputSave({ loanId: id, key });
    };
    const saveAmountHandler = (params) => {
        let user_data = JSON.parse(localStorage.getItem('user_information'));
        if (params.save) {
            if (params.amount) {
                let incentiveInput={
                    id:params.id,
                    created_by:user_data.id,
                    created_by_name:user_data.name
                }
                if(params.type==="deduction"){
                    incentiveInput["deduction"]=+params.amount;  
                }else{
                    incentiveInput["salary_arrear"]=+params.amount;  
                }
                
                setLoading(true)
                executeGraphQLMutation(UPDATE_DIFF_AMOUNT,{incentiveInput}, client).then(response => { 
                    setLoading(false);
                    if(response && response.data && response?.data?.incentive_amount_update && !response?.data?.incentive_amount_update?.is_error) {
                        let data=response?.data?.incentive_amount_update.data;
                        let tableDataArr = JSON.parse(JSON.stringify(tableData));
                       
                        for (let dataArr of tableDataArr) {
                            if(dataArr.user_id === +params.user_id){
                                for (let row of dataArr.items) {
                                    if (+row.id === +data.id) {
                                        if(params.type==="deduction"){
                                            row["deduction"] = data.deduction;  
                                        }else{
                                            row["salary_arrear"] = data.salary_arrear;  
                                        }
                                        row["final_amount"] = data.final_amount;
                                    }
                                }
                            }
                        }
                        setTableData(tableDataArr);
                        getIncentiveList({ ...filterData,...{page_number:1}});
                        toast.success(response.data.incentive_amount_update.message);
                    }else{
                        toast.error(response.data.incentive_amount_update.message)
                    }
                }).catch((error) => {
                    setLoading(false);
                });
                
            } else {
                let msg="Salary Arrear amount should not be blank"
                if(params.type==="deduction"){
                    msg="Deduction amount should not be blank"
                }
                toast.error(msg)
                return false;
            }
        }
        if (!params.save) {
            setTableData(dealerDataState?.data?.userinfo);
        }
        setShowInputSave({ loanId: 0 });
    };

   
    const showModalViewTimeLine = (action, params) => {
        setActionData(params);
        setViewTimeLine(action);
        document.body.classList.remove("overflow-hidden");

        if (action) {
            document.body.classList.add("overflow-hidden");
        }
    };


    const handleSubTableAcceptReject = (params) => {
        let { status } = params;
        setPopBoxData(params);  
        if(params.response){
            setLoading(true);
            let approve_input= {
                "approve_input": { 
                    "month": popBoxData?.incentive_month,  
                    "child_id": popBoxData?.id,
                    "status": popBoxData.status_id,
                    "created_by": USER_DATA?.id
                }
            } 
            executeGraphQLMutation(UPDATE_STATUS,approve_input, client).then(response => { 
                setLoading(false);  
                if(response && response.data && response?.data?.approve_amount && !response?.data?.approve_amount?.is_error) { 
                    toast.success(response.data.approve_amount.message);
                }else{
                    toast.error(response.data.approve_amount.message)
                }
                getIncentiveList({ ...filterData});
            }).catch((error) => {  }); 
        }
        document.body.classList.remove("overflow-hidden");
        if (status) {
            document.body.classList.add("overflow-hidden");
        }

    };

    const confirmationModalResponse = (params) => {  
        handleSubTableAcceptReject(params)
    }

    const handleRequestData = (params) => {
        let apiParams = {
            page_number: params.page_no
        }
        getIncentiveList({ ...filterData, ...apiParams });
    }

    const getIncentiveList = async (params) => {
        const result = await fetchListingData(params)
        handleTableData(result)
    }

    const handleTableData = (result) => {
        if (result?.data?.incentive_list) {
            let data = result.data.incentive_list?.userinfo || [];
            let filteredId=[];
            if(data.length){
                for(let userdata of data){
                    const entries = userdata?.items;
                    let dataExist = entries.map(item => ({ scheme_type_id: item.scheme_type_id, incentive_month: item.incentive_month,id:item.id,status:item.status,status_name:item.status_name })).filter((value, index, self) => index === self.findIndex((t) => (t.scheme_type_id === value.scheme_type_id && t.incentive_month === value.incentive_month && [1,4].includes(value.status)))); 
                    if(dataExist.length){
                       let ids=dataExist.map((el)=>el.id);
                       ids.forEach(item => filteredId.push(item));
                    }
                }
            }
            let disabled=(filteredId.length)? false:true;
            setSelectAllDisabled(disabled);
            setTableData(data)
            setPaginationData({ ...result.data.incentive_list?.pagination || {} });
        } else {
            setTableData([])
            setPaginationData({})
            return toast.error(result?.errors?.[0]?.message);
        }
        setShowInputSave({ loanId: 0, key: "" })
    }

    // const toggleTable = (user_id,type_id,trIdArr) => {
    //     let dataset={user_id:user_id,type_id:type_id,idArr:trIdArr};
    //     let trData=JSON.parse(JSON.stringify(trShowData));
    //     if(trData && trData.user_id!==user_id || trData.type_id!==type_id){
    //         if(trData.idArr.length){
    //             trData.idArr.map(item=>{
    //                 let id=`${trData.user_id}_${item}`;
    //                 document.getElementById(id).classList.add("d-none");
    //             }) 
    //         }
    //         if(trIdArr.length){
    //             trIdArr.map(item=>{
    //                 let id=`${user_id}_${item}`;
    //                 document.getElementById(id).classList.remove("d-none");
    //             }) 
    //         }
    //         setTrShowData(dataset);
    //         setTrShow(true);
    //     }else{
    //         if(trShow===false){
    //             if(trIdArr.length){
    //                 trIdArr.map(item=>{
    //                     let id=`${user_id}_${item}`;
    //                     document.getElementById(id).classList.remove("d-none");
    //                 }) 
    //             }
    //             setTrShowData(dataset);
    //             setTrShow(true);
    //         }else{
    //             if(trIdArr.length){
    //                 trIdArr.map(item=>{
    //                     let id=`${user_id}_${item}`;
    //                     document.getElementById(id).classList.add("d-none");
    //                 }) 
    //             }
    //             setTrShowData({user_id:'',type_id:'',idArr:[]});
    //             setTrShow(false);
    //         }
    //     }
    // };
    
    const toggleShowTable = (user_id,LoandId) => {
        setTabelShow(0);
        if (tabelShow !== LoandId) {
            setTabelShow(LoandId);
        }
        
    };

    const downloadurl = (id,type) => {
        setLoading(true);
        executeGraphQLQuery(GET_PAID_URL(id,type), client).then(response => { 
            setLoading(false);
            if(response && response?.data && response?.data?.download_url && !response?.data?.download_url.is_error) {
                const imageUrl =response?.data?.download_url.data ;
                const link = document.createElement('a');
                link.href = imageUrl;
                link.target="_blank";
                link.download = 'paid-image.jpg'; 
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }else{
                toast.error(response?.data?.download_url.message);
            }
        }).catch((error) => {
        
        });
        
    };
    

    useEffect(() => {
        if (data) {
            setTableData([]);
            handleTableData(data)
        }
    }, [data])

    const showLeadList = (isLoad, id, item) =>{
        if(item.csv_log_id && item.addedByUser){
            toast.error(`Loan ID Raw data is not available as this incentive has been uploaded by ${item.addedByUser}`)
        } else {
            if(isLoad){
                setUserLoanDetail(id)
            }else{
                setUserLoanDetail(null)
            }  
        } 
    }
    
    return (
        <>  
            <FullPageLoader show={loading} />
            <div className={(pageType===2)?"data-table dealer-list-table dealerview":"data-table dealer-list-table"}>
                <table>
                    <thead>
                        <tr id={1}>
                            <th>
                                <div className="custom-control custom-checkbox">
                                    {!loading && (
                                        <input
                                            id="sli0"
                                            type="checkbox"
                                            className="custom-control-input"
                                            checked={checkedAll}
                                            onChange={(e) => selectAll(e)}
                                            disabled={selectAllDisabled}
                                        />
                                    )}
                                    <label
                                        htmlFor="sli0"
                                        className="custom-control-label"
                                    ></label>
                                </div>

                            </th>
                            <th>Dealership Name</th>
                            <th>Type</th>
                            {pageType ==2 ?   <th>Incentive <br></br>Month</th> : null }
                            <th className='w10'>Disbursal and <br></br> Total GMV</th>
                            <th>Incentive</th>
                            <th>Bank Details</th>
                            <th>Salary <br></br>Arrear</th>
                            <th>Deduction</th>
                            <th>Final <br></br> Incentive</th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!loading && tableData.length > 0 ? (
                           
                            tableData.map((data) => {
                                const entries = data?.items || []
                                return entries.map((item, idx) => {
                                    if(idx === 0 || idx!== 0 && entries[idx-1]["scheme_type_id"]!==item.scheme_type_id && item.modify_status!==3 || (idx!== 0  && entries[idx-1]["scheme_type_id"]===item.scheme_type_id && entries[idx-1]["incentive_month"]!==item.incentive_month && item.modify_status!==3)){
                                      
                                    let bankDetails=``;
                                    if(item.bank_name){
                                        bankDetails+=([1,4].includes(item.status))?data.bank_name:item.bank_name;
                                    }
                                    if(item.account_number){
                                        bankDetails+=([1,4].includes(item.status))?" "+data.account_number:" "+item.account_number;
                                    }
                                    if(item.beneficial_name){
                                        bankDetails+=([1,4].includes(item.status))?" "+data.beneficial_name:" "+item.beneficial_name;
                                    }
                                    let modify_status=item.modify_status;
                                    let modify_status_name=item.modify_status_name;
                                    let filterdata=[];
                                    let filterIdsArr=[];
                                    let display_none='';
                                    let overwriteData=[];
                                    if(idx === 0 || (idx!== 0 && entries[idx-1]["scheme_type_id"]!==item.scheme_type_id) ||(idx!== 0  && entries[idx-1]["scheme_type_id"]===item.scheme_type_id && entries[idx-1]["incentive_month"]!==item.incentive_month)){
                                        if([2,3].includes(item.modify_status)){
                                            modify_status=item.modify_status;
                                            modify_status_name=item.modify_status_name;
                                        }else{
                                            let schemeTypeData=entries.filter((el)=>el.scheme_type_id===item.scheme_type_id); 

                                            filterdata=entries.filter((el)=>el.scheme_type_id===item.scheme_type_id && el.id!==item.id);

                                            if(pageType ==2){
                                                schemeTypeData=entries.filter((el)=>el.scheme_type_id===item.scheme_type_id && el.incentive_month===item.incentive_month);

                                                filterdata=entries.filter((el)=>el.scheme_type_id===item.scheme_type_id && el.incentive_month===item.incentive_month && el.id!==item.id);
                                            } 
                                            
                                            const lastEntryAlternative = (schemeTypeData.length>1)?schemeTypeData[1]:{};
                                            if(Object.keys(lastEntryAlternative).length){
                                                modify_status=lastEntryAlternative.modify_status;
                                                modify_status_name=lastEntryAlternative.modify_status_name;
                                            }else{
                                                modify_status=item.modify_status;
                                                modify_status_name=item.modify_status_name;
                                            }

                                            filterdata=filterdata.filter((el)=>el.modify_status!==3);
                                            overwriteData=(modify_status===1)?filterdata:[];
                                           
                                            if(filterdata.length){
                                                filterIdsArr=filterdata.map((el)=>el.id);
                                            }
                                            display_none='';
                                        }
                                        if(idx!== 0){
                                            display_none="more-data-row";  
                                        }

                                        
                                    }else{
                                        display_none="more-data-row";
                                    }
                                   
                                    /* Month View */
                                    let MODIFY_STATUS = (idx === 0 || idx!== 0 && entries[idx-1]["scheme_type_id"]!==item.scheme_type_id) ;
                                    /* Dealer View */
                                    if(pageType ==2){
                                        MODIFY_STATUS = (idx === 0 || (idx!== 0 &&  entries[idx-1]["scheme_type_id"]!==item.scheme_type_id) || (idx!== 0  && entries[idx-1]["scheme_type_id"]===item.scheme_type_id && entries[idx-1]["incentive_month"]!==item.incentive_month))  
                                    } 
                                    display_none+= (tabelShow === item.id) ? " active" : "";
                                    return (
                                    <>
                                        {
                                        (item.modify_status!==1)?(<tr className={display_none} key={data.user_id + "_" + idx} id={data.user_id + "_"+item.id}>    
                                            {
                                                (idx!== 0)?<td></td>:null
                                            } 
                                            {
                                                (idx!== 0)?<td></td>:null
                                            } 
                                            {
                                                (idx === 0 && [1,4].includes(item.status))? 
                                                <td className=''>
                                                    <div className="custom-control custom-checkbox">
                                                        <input
                                                            id={`sli${idx + 1}`}
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            onChange={(e) =>
                                                                handleSingleCheckboxChange(e, data)
                                                            }
                                                            checked={(checkedAll)? checkedAll: multiSelectedDealers.includes(data.user_id)}
                                                            disabled={false}
                                                        />
                                                        <label
                                                            htmlFor={`sli${idx + 1}`}
                                                            className="custom-control-label"
                                                        ></label>
                                                    </div>
                                                </td>:(idx === 0)?<td  className=''></td>:null
                                            }
                                                
                                            {
                                            (idx === 0)?
                                                <td title={`${data.user_name} GCD-${data.user_id}`}>
                                                    <span className='d-block truncate'>{data.user_name}</span>
                                                    <span>{`GCD-${data.user_id}`}</span>
                                                </td>:null  
                                            }
                                            
                                        
                                            <td>
                                                { MODIFY_STATUS && (
                                                    <>
                                                        <span className="truncate">{item.scheme_type_name}</span>
                                                        { 
                                                            (modify_status !==5)?
                                                            <span className={(modify_status ===1)?"overwritting d-flex":(modify_status ===2)?"paid-span":(modify_status === 3)?"onhold-span":''} onClick={()=>{toggleShowTable(data.user_id,item.id)}} >{modify_status_name}
                                                            {(modify_status ===1 && filterdata.length)?<i className="ic-keyboard_arrow_down"></i>:null}</span>:''
                                                        }
                                                    </>
                                                )}
                                            </td>
                                            { pageType ==2 ?  <td>
                                                <span className="truncate">{moment(new Date(item.incentive_month)).format('MMM YYYY') }</span>
                                            </td> : null }
                                            <td>
                                                <span title={`${item.total_disbursal} Disbursal GMV ${HELPER.addCurrencyFormatting(item.total_gmv)} `}>{item.total_disbursal} Disbursal
                                                {
                                                    userLoanDetail == item.id ? 
                                                    <span onClick={()=>showLeadList(false,item.id, item)}> <i className="ic-keyboard_arrow_up "></i> </span> : 
                                                    <span onClick={()=>showLeadList(true,item.id, item)}> <i className="ic-keyboard_arrow_down "></i> </span>
                                                }  
                                                </span>
                                                 <br/>
                                                 <span>GMV   {HELPER.addCurrencyFormatting(item.total_gmv)}</span>
                                            </td>
                                            <td>
                                                <span className="truncate">{HELPER.addCurrencyFormatting(item.amount)}</span>
                                            </td>
                                            <td>
                                                <span className="truncate" title={`${bankDetails}`} >{([1,4].includes(item.status))?data.bank_name ||"N/A":item.bank_name || "N/A"}<br></br>  {([1,4].includes(item.status))?data.account_number:item.account_number} <br></br>{([1,4].includes(item.status))?data.beneficial_name:item.beneficial_name}</span>
                                            </td>
                                            <td>
                                                <CurrencyInputField
                                                    inputProps={{
                                                        id: `salary_arrear_${item.id}`,
                                                        type: "text",
                                                        name: "salary_arrear",
                                                        placeholder: "Salary",
                                                        value:`${item.salary_arrear}`,
                                                        disabled:([1,4].includes(item.status) && [4,2,5].includes(item.modify_status))?false:true,
                                                        className: `txt-value-input`,
                                                        title: "Salary Arrear",
                                                        maxLength: 16
                                                    }}
                                                    onChange={(e) =>handleInputChange("salary_arrear",`salary_arrear_${item.id}`,data.user_id,item.id, e)}
                                                />
                                                {[1,4].includes(item.status) && showInputSave.loanId === item.id && showInputSave.key === `salary_arrear_${item.id}` && (
                                                    <div className="amt-edit-opt">
                                                        <button className="btn-primary" onClick={() =>saveAmountHandler({save: true,id:item.id,type: "salary_arrear",amount: item.salary_arrear,user_id:data.user_id})}>Save</button> 

                                                        <i className="ic-clearclose" onClick={() =>saveAmountHandler({save: false })}></i> 
                                                    </div>
                                                )}
                                            
                                            </td>
                                            <td>
                                                <CurrencyInputField
                                                    inputProps={{
                                                        id: `deduction_${item.id}`,
                                                        type: "text",
                                                        name: "Deduction Amount",
                                                        placeholder: "Deduction",
                                                        value: `${item.deduction}`,
                                                        disabled:([1,4].includes(item.status) && [4,2,5].includes(item.modify_status))?false:true,
                                                        className: `txt-value-input`,
                                                        title: "deduction",
                                                        maxLength: 16
                                                    }}
                                                    onChange={(e) =>handleInputChange("deduction",`deduction_${item.id}`,data.user_id,item.id, e)}
                                                />
                                                {[1,4].includes(item.status) && showInputSave.loanId === item.id && showInputSave.key === `deduction_${item.id}` && (
                                                    <div className="amt-edit-opt">
                                                        <button className="btn-primary" onClick={() =>saveAmountHandler({save: true,id:item.id,type: "deduction",amount: item.deduction,user_id:data.user_id})}>Save</button> 
                                                         <i className="ic-clearclose" onClick={() =>saveAmountHandler({save: false })}></i> 
                                                    </div>
                                                )}
                                            </td>
                                            
                                            <td>
                                                <span className="truncate">{HELPER.addCurrencyFormatting(item.final_amount)}</span>
                                            </td>
                                            <td>
                                                <span className="truncate"><span className={(item.status === 3)?"paid-span":(item.status === 4)?"onhold-span":''}>{item.status_name}</span></span>
                                            </td>
                                            <td>
                                                <ul className="action-btn">
                                                    {(![1,3].includes(item.modify_status)) &&
                                                        <li className="" title="History">
                                                            <i className="ic-history"
                                                                onClick={() => showModalViewTimeLine('LIST', { id:data["user_id"],name:data["name"],gcd_code:data["gcd_code"]})}
                                                            ></i>
                                                        </li>
                                                    }
                                                    {[1,4].includes(item.status) && ([2,4,5].includes(item.modify_status)) && <li className='checkicn-bg' >
                                                        <i
                                                            className="ic-check"
                                                            onClick={() => handleSubTableAcceptReject({
                                                                status: true,
                                                                msg: "Are you sure you want to accept this?",
                                                                id: item.id, 
                                                                incentive_month: item.incentive_month,
                                                                status_id: 2
                                                            })}
                                                        ></i>
                                                    </li>
                                                    }
                                                    {[1,4].includes(item.status) && ([2,4,5].includes(item.modify_status)) && <li className='closeicn-bg'>
                                                        <i
                                                            className="ic-clearclose"
                                                            onClick={() => handleSubTableAcceptReject({
                                                                status: true,
                                                                msg: "Are you sure you want to reject this?",
                                                                id: item.id, 
                                                                incentive_month: item.incentive_month,
                                                                status_id: 5
                                                            })}
                                                        ></i>
                                                    </li>
                                                    }

                                                    {item.status == 3 && 
                                                    <>
                                                        <li>
                                                            <img onClick={()=>{downloadurl(item.id,'paid')}} src={downloadIcon} className="" alt='logo' />
                                                        </li>
                                                        <li>
                                                            <span onClick={()=>{downloadurl(item.id,'tc')}}>TC</span>
                                                        </li>
                                                    </>
                                                    }
                                                </ul>
                                            </td>
                                        </tr>):null
                                        }
                                        {
                                            userLoanDetail == item.id ? <LoanTable key={item.id} list={item.loan_list} pageType={pageType} /> : null
                                        }
                                        
                                        {
                                             tabelShow === item.id && overwriteData.length>0 ?(<tr className={(pageType ==1)?"overwrite-sub-table":"overwrite-sub-table overwrite-sub-tableDealer"} key={"sub" + item.id} id={"sub" + item.id}>
                                                <td colSpan={(pageType===1)?11:12} className='br-topNone'>
                                                    <table>
                                                        {   
                                                            overwriteData.length && overwriteData.map((it) => {
                                                                let bankDetails=``;
                                                                if(it.bank_name){
                                                                    bankDetails+=it.bank_name;
                                                                }
                                                                if(it.account_number){
                                                                    bankDetails+=" "+it.account_number;
                                                                }
                                                                if(it.beneficial_name){
                                                                    bankDetails+=" "+it.beneficial_name;
                                                                }   
                                                            return (
                                                                <tr> 
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    {(pageType===2)?<td></td>:null}
                                                                    <td>
                                                                        <span className="truncate" title={`${it.total_disbursal} Disbursal GMV ${HELPER.addCurrencyFormatting(it.total_gmv)} `}>{it.total_disbursal} Disbursal <br></br>GMV   {HELPER.addCurrencyFormatting(it.total_gmv)}</span>
                                                                    </td>
                                                                    <td>
                                                                        <span className="truncate">{HELPER.addCurrencyFormatting(it.amount)}</span>
                                                                    </td>
                                                                    <td>
                                                                        <span className="truncate" title={`${bankDetails}`} >{it.bank_name || "N/A"}<br></br>  {it.account_number} <br></br>{it.beneficial_name}</span>
                                                                    </td>
                                                                    <td>
                                                                        <CurrencyInputField
                                                                            inputProps={{
                                                                                id: `salary_arrear_${it.id}`,
                                                                                type: "text",
                                                                                name: "salary_arrear",
                                                                                placeholder: "Salary",
                                                                                value: `${it.salary_arrear}`,
                                                                                disabled:true,
                                                                                className: `txt-value-input`,
                                                                                title: "Salary Arrear"
                                                                            }}
                                                                            />
                                                                    </td>
                                                                    <td>
                                                                        <CurrencyInputField
                                                                            inputProps={{
                                                                                id: `deduction_${it.id}`,
                                                                                type: "text",
                                                                                name: "deduction_",
                                                                                placeholder: "Deduction",
                                                                                value: `${it.deduction}`,
                                                                                disabled:true,
                                                                                className: `txt-value-input`,
                                                                                title: "Deduction"
                                                                            }}
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <span className="truncate">{HELPER.addCurrencyFormatting(it.final_amount)}</span>
                                                                    </td>
                                                                    <td>
                                                                        {/* <span className="truncate"><span className={(item.status === 3)?"paid-span":(item.status === 4)?"onhold-span":''}>{item.status_name}</span></span> */}
                                                                    </td>
                                                                    <td>
                                                                    </td>
                                                                </tr>
                                                            )
                                                            })  
                                                        }
                                                    </table>
                                                </td>
                                            </tr>):null
                                        }
                                        
                                    </>
                                        
                                         
                                    )
                                   }
                                })
                            })
                        ) : loading ? (
                            <tr>
                                <td>&nbsp;</td>
                            </tr>
                        ) : (
                            <tr>
                                <td colSpan={9} className="no-data">
                                    No Data Found
                                </td>
                            </tr>
                        )}


                        
                    </tbody>
                </table>
            </div>

            {(paginationData && paginationData['total'] > 10 ) && <Pagination paginateData={{
                page: 1,
                loading: false,
                totalLengthWithoutPagination: paginationData['total'],
                paginationData,
                filteredData: filterData
            }}
                sendRequestDataToParent={handleRequestData} />}


            {viewTimeLine &&
                <DealerHistory
                    viewTimeLine={(viewTimeLine !== '') ? true : false}
                    data={actionData}
                    type={viewTimeLine}
                    showModalViewTimeLine={showModalViewTimeLine}
                />
            }

            {
                <ConfirmationModal
                    confirmationData={{
                        show: popBoxData.status,
                        confirmationText: popBoxData.msg,
                    }}
                    confirmationModalResponse={confirmationModalResponse}
                />
            }

        </>
    );
};

export default DealerListTable;
