import React, { useState, useEffect } from 'react'
import { executeGraphQLQuery } from "../../common/executeGraphQLQuery";
import { useApolloClient } from '@apollo/client';
import { CSVLink } from "react-csv";
import * as XLSX from 'xlsx';
import { EXPORT_DEALER_INCENTIVE_DATA } from "../../services/dealerdata.gql";
import * as HELPER from '../../config/helper';
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import FullPageLoader from "../common/FullPageLoader";

function ExportAllData({ filters, activeTab }) {

    const client = useApolloClient();
    const [loading, setLoading] = useState(false);
    const [csvData, setCSVData] = useState([]);
    const [isDownloadReady, setIsDownloadReady] = useState(false);
    const [fileType, serFileType] = useState('dealer')
    const { t } = useTranslation("common");

    const exportHeaders = (activeTab === 1) ? [
        { label: t('DEALER.DEALERSHIP_NAME'), key: "name" },
        { label: t('DEALER.TYPE'), key: "type" },
        { label: t('DEALER.DISBURSAL_GMV'), key: "disbursal_gmv" },
        { label: t('FORM.INCENTIVE'), key: "incentive" },
        { label: t('DEALER.BANK_DETAILS'), key: "bank_details" },
        { label: t('DEALER.DEDUCTION'), key: "deduction" },
        { label: t('DEALER.SALARY_ARREAR'), key: "salary_arrear" },
        { label: t('DEALER.FINAL_INCENTIVE'), key: "final_incentive" },
        { label: t('DEALER.STATUS'), key: "status" },
    ] : [{ label: t('DEALER.DEALERSHIP_NAME'), key: "name" },
    { label: t('DEALER.TYPE'), key: "type" },
    { label: t('DEALER.INCENTIVE_MONTH'), key: "incentive_month" },
    { label: t('DEALER.DISBURSAL_GMV'), key: "disbursal_gmv" },
    { label: t('FORM.INCENTIVE'), key: "incentive" },
    { label: t('DEALER.BANK_DETAILS'), key: "bank_details" },
    { label: t('DEALER.DEDUCTION'), key: "deduction" },
    { label: t('DEALER.SALARY_ARREAR'), key: "salary_arrear" },
    { label: t('DEALER.FINAL_INCENTIVE'), key: "final_incentive" },
    { label: t('DEALER.STATUS'), key: "status" },];

    useEffect(() => {
        if (isDownloadReady) {
            document?.getElementById("csvDownloadLink")?.click();
            setIsDownloadReady(false)
        }
    }, [isDownloadReady])


    const downloadFile = (params) => {
        const { sheet1Data, sheet2Data, sheet3Data, sheet4Data } = params;
        const wb = XLSX.utils.book_new();

        const sheet1 = XLSX.utils.json_to_sheet(sheet1Data);
        XLSX.utils.book_append_sheet(wb, sheet1, 'Incentive Data');

        const sheet2 = XLSX.utils.json_to_sheet(sheet2Data);
        XLSX.utils.book_append_sheet(wb, sheet2, 'Monthly');

        const sheet3 = XLSX.utils.json_to_sheet(sheet3Data);
        XLSX.utils.book_append_sheet(wb, sheet3, 'Quarterly');

        const sheet4 = XLSX.utils.json_to_sheet(sheet4Data);
        XLSX.utils.book_append_sheet(wb, sheet4, 'Annually');

        XLSX.writeFile(wb, 'all-disbursal-data.xlsx');
    };

    const handleExport = async () => {
        setLoading(true)
        let updatedFilters = { filter_list: { ...filters, view_type: activeTab } }
        delete updatedFilters?.['filter_list']?.page_number //delete pagination for export

        let sheetObject = {
            sheet1Data: [],
            sheet2Data: [],
            sheet3Data: [],
            sheet4Data: []
        }
        const result = await executeGraphQLQuery(EXPORT_DEALER_INCENTIVE_DATA(), client, updatedFilters);
        let newCSVData = [];
        if (result && result?.data && result?.data?.incentive_list && result?.data?.incentive_list?.userinfo) {
            let userinfo = result?.data?.incentive_list?.userinfo;
            for (let ele of userinfo) {
                let entries = ele.items || [];
                ele.loan_list = ele.items[0]?.csv_log_id ? [] : ele.loan_list; 
                /*--------  tab wise csv-------- */ 
                // let maxSchemeTypeObject = entries.reduce((maxObj, currentObj) => 
                //     (currentObj.scheme_type_id > maxObj.scheme_type_id ? currentObj : maxObj), 
                //     entries[0]
                // );

                let dataUploadedByCsv = ele.items?.some(item => item.csv_log_id && item.csv_log_id > 0);
 
                if(entries.length){
                    let data = getItemLOb(0,ele,ele.items[0],entries); 
                   /* --- scheme type wise */
                    for(let itemObject of entries){  
                       if(!dataUploadedByCsv){
                         for (let loanInfo of itemObject.loan_list) {   
                            let schemeOb = {
                                'Dealership Name': data.name, 
                                'Loan ID': loanInfo.loan_id,
                                'Customer Name': loanInfo.customer_name,
                                'Disbursed Date': loanInfo.disbursed_date,
                                'Disbursed Date Type': loanInfo.disbursed_type,
                                'Loan Value': loanInfo.amount,
                                'Loan Amount Pass Through': loanInfo.loan_amount_pass_through,
                                "ADP":loanInfo.adp_type,
                                "SO Name/Agent Name":loanInfo.so_name,
                                "Business Line":loanInfo.business_line
                            }  
                            if(itemObject.scheme_type_id == 1){ 
                                sheetObject.sheet2Data.push(schemeOb);
                            }else if(itemObject.scheme_type_id == 2){
                                sheetObject.sheet3Data.push(schemeOb);
                            }else if(itemObject.scheme_type_id == 3){
                                sheetObject.sheet4Data.push(schemeOb);
                            } 
                        } }
                    }
                   
                }
              
                /* ------- end tab wise csv ------- */
                for (const [idx, item] of entries.entries()) {
                    let itemStatus = true;
                    if (idx !== 0 && entries[idx - 1]["scheme_type_id"] === item.scheme_type_id && item.modify_status === 3) {
                        itemStatus = false;
                    } else if (idx !== 0 && entries[idx - 1]["scheme_type_id"] === item.scheme_type_id && entries[idx - 1]["modify_status"] === 3 && item.modify_status !== 3) {
                        itemStatus = false;
                    }
                    if (itemStatus) { 
                        let data = getItemLOb(idx,ele,item,entries);  
                        newCSVData.push(data);
                        let incentiveMonth = activeTab === 2 ? {'Incentive Month' : data.incentive_month} : {}
                        sheetObject.sheet1Data.push({
                            'Dealership Name': data.name,
                            'Type': data.type,
                            ...incentiveMonth,
                            'Disbursal & Total GMV': data.disbursal_gmv,
                            'Incentive': data.incentive,
                            'Bank Details': data.bank_details,
                            'Deduction': data.deduction,
                            'Salary Arrear': data.salary_arrear,
                            'Final Incentive': data.final_incentive,
                            'Status': data.status
                        })
                    }

                }
            }
        }

        setLoading(false)

        if (fileType == 'dealer') {
            if (newCSVData?.length) {
                setCSVData(newCSVData)
                setIsDownloadReady(true)
            } else {
                toast.error(t("DEALER.NO_DATA_FOUND"))
                setCSVData([])
                setIsDownloadReady(false)
            }
        } else {  
           downloadFile(sheetObject)
        }
        return true;
    }

    const getItemLOb = (idx,ele,item,entries) => { 
        let status = item.status_name;
        if (idx !== 0) {
            let filterData = entries.filter((el) => el.scheme_type_id === item.scheme_type_id && el.incentive_month === item.incentive_month);
            if (filterData.length && filterData[0].id === item.id) {
                status = item.status_name;
            } else {
                status = '';
            }
        }
        let bankDetails = ``;
        if (item.bank_name) {
            bankDetails += ([1, 4].includes(item.status)) ? ele.bank_name : item.bank_name;
        }
        if (item.account_number) {
            bankDetails += ([1, 4].includes(item.status)) ? " " + ele.account_number : " " + item.account_number;
        }
        if (item.beneficial_name) {
            bankDetails += ([1, 4].includes(item.status)) ? " " + ele.beneficial_name : " " + item.beneficial_name;
        }
        let data = {
            name: ele.user_name + " GCD " + ele.gcd_code,
            type: item.scheme_type_name,
            disbursal_gmv: item.total_disbursal + " Disbursal GMV " + HELPER.addCurrencyFormatting(item.total_gmv),
            incentive: HELPER.addCurrencyFormatting(item.amount),
            bank_details: bankDetails || "N/A",
            deduction: (item.deduction) ? HELPER.addCurrencyFormatting(item.deduction) : '',
            salary_arrear: (item.salary_arrear) ? HELPER.addCurrencyFormatting(item.salary_arrear) : '',
            final_incentive: HELPER.addCurrencyFormatting(item.final_amount),
            status: status
        }
        if (activeTab === 2) {
            data["incentive_month"] = `${item.incentive_month}`
        }
        return data;
    }



    return (
        <>
            <FullPageLoader show={loading} />

            <div className="modal-header">
                <h2 className="t-left">Export Data?</h2>
            </div>
            <div className="modal-body" >
                <div className="radio-btn">

                    <div className="custom-control custom-checkbox m-md-b">
                        <input id="Incentive" type="radio" className="custom-control-input " name="incentive_scheme_selected" value="dealer" onClick={() => serFileType('dealer')} checked={fileType == 'dealer'} /><label className="custom-control-label"><span>Dealer Incentive Data</span></label>
                    </div>
                    <div className="custom-control custom-checkbox m-md-b">
                        <input id="Incentive" type="radio" className="custom-control-input " name="incentive_scheme_selected" value="all" onClick={() => serFileType('all')} checked={fileType == 'all'} /><label className="custom-control-label"><span>All Disbursal Data</span></label>
                    </div>
                </div>
                <button className="btn-line btn-blue m-sm-l" onClick={handleExport}>
                    Export Data
                </button>

                {isDownloadReady && (
                    <CSVLink data={csvData} headers={exportHeaders} filename={"dealer-incentive-data.csv"} id="csvDownloadLink" style={{ display: "none" }} />
                )}
            </div>
        </>
    )
}

export default ExportAllData