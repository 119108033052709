import React, { Component } from "react";
import { NavLink } from 'react-router-dom';
//import { Accordion, Card, Button, DropdownButton, Dropdown } from "react-bootstrap";
import Notifaction from './Notification'
import logo_img from "../../webroot/images/logo.svg";
import secureStorage from '../../config/encrypt';
import { withTranslation } from 'react-i18next';
import { toast } from "react-toastify";
import { Navigate } from "react-router";
import * as HELPER from '../../config/helper';

// const Navigation = () => {

//     this.state = {
//         activeId: null  // nothing selected by default, but this is up to you...
//     }

//     handleClick(event, id) {
//         this.setState({ activeId: id })
//     }

class Navigation extends Component {
    _isMount = false
    constructor(props) {
        super(props);
        this.state = {
            activeId: null,  // nothing selected by default, but this is up to you...
            redirect: false,
            name: "",
            role:""
        }
    }
    componentDidMount = () => {
        this._isMount = true
        let result = JSON.parse(localStorage.getItem('user_information')); //secureStorage.getItem("authUserInfo");
         
        if (!result) {
            this.setState({ redirect: false });
        } else { 
            this.setState({ name: result.name || 'User', role: result?.role || 'NA' });
        }
    }

    handleClick(event, id) {
        this.setState({ activeId: id })
    }
    changeLanguage = (lang) => {
        if (this._isMount) {
            secureStorage.setItem('lang', lang);
            this.props.i18n.changeLanguage(lang)
        }
    }
    logOutUser = async () => {
        localStorage.clear()
        this.setState({ redirect: true });
        toast.success(`LogOut Successfully`);
        let result = secureStorage.getItem("authUserInfo");
        if (result) {
            secureStorage.removeItem("authUserInfo"); 
        }
    };
    checkVisibleMenu = (menus) => {
        return menus.filter(menu => menu.show_in_menu)
    }
    linkUrl = (menu) => {
        let menuLink = '#'
        if (menu['action']) {
            menuLink = '/' + menu['action']
        } 
        return menuLink 
    }
    getCurrentClass = (menuData) => {
        let currentPath = window.location.pathname.split('/');
        let activeClass = '';
        currentPath = currentPath.filter(path => path)  
        if (currentPath.length) {
            let stringMenuData = JSON.stringify(menuData)  
            let checkActiveUrl = HELPER.isUserHasAccessPage(currentPath[0], stringMenuData) 
            if (checkActiveUrl) activeClass = 'active'; 
        } else { 
            if (menuData.name === 'Dashboard') {
                activeClass = 'active';
            }
        }
        return activeClass;
    }
    render() {
        const { name , role } = this.state; 
        let language = secureStorage.getItem("lang") === "id" ? "ID" : "EN";
        const authUserInfo = secureStorage.getItem('authUserInfo') || {};
        const loggedInUser = JSON.parse(localStorage.getItem('user_information'));   

        let isAuthorized = (authUserInfo && authUserInfo['headerMenu']) ? HELPER.isUserHasAccessPage() : true 
        if (this.state.redirect || !isAuthorized) {
            secureStorage.removeItem("authUserInfo");
            return <Navigate to="/login" />;
        }
       
        const menuHeaders = authUserInfo && authUserInfo.headerMenu ? JSON.parse(authUserInfo.headerMenu) : [
            {
                "id": 3,
                "name": "Payout Detail",
                "description": "Payout Detail",
                "status": 1,
                "module": "payout-details",
                "controller": "payout-details",
                "action": "payout-details",
                "parent": 0,
                "order_num": null,
                "show_in_menu": 0,
                "created_at": "2022-10-27T22:08:12.000Z",
                "updated_at": "2022-10-27T22:11:26.000Z",
                "childMenu": []
            },
            {
                "id": 1,
                "name": "Dashboard",
                "description": "Dashboard",
                "status": 1,
                "module": "dashboard",
                "controller": "dashboard",
                "action": "dashboard",
                "parent": 0,
                "order_num": 1,
                "show_in_menu": 1,
                "created_at": "2022-10-26T22:47:06.000Z",
                "updated_at": "2022-10-26T23:00:36.000Z",
                "childMenu": []
            },
            {
                "id": 2,
                "name": "Config Panel",
                "description": "Config Panel",
                "status": 1,
                "module": "config panel",
                "controller": "",
                "action": "",
                "parent": 0,
                "order_num": 2,
                "show_in_menu": 1,
                "created_at": null,
                "updated_at": "2023-07-05T11:45:08.000Z",
                "childMenu": [
                    {
                        "id": 4,
                        "name": "Dealer Configuration",
                        "description": "Dealer Configuration",
                        "status": 1,
                        "module": "config panel",
                        "controller": "dealer_config",
                        "action": "dealer-config",
                        "parent": 2,
                        "order_num": 1,
                        "show_in_menu": 1,
                        "created_at": "2023-07-05T11:45:05.000Z",
                        "updated_at": "2023-07-05T11:45:05.000Z",
                        "childSubMenu": []
                    },
                    {
                        "id": 5,
                        "name": "Dealer Incentive",
                        "description": "Dealer Incentive",
                        "status": 1,
                        "module": "config panel",
                        "controller": "dealer_list",
                        "action": "dealer-list",
                        "parent": 2,
                        "order_num": 2,
                        "show_in_menu": 1,
                        "created_at": "2023-07-05T11:45:05.000Z",
                        "updated_at": "2023-07-05T11:45:05.000Z",
                        "childSubMenu": []
                    },
                    {
                        "id": 5,
                        "name": "Exclusion Configuration",
                        "description": "Exclusion Config",
                        "status": 1,
                        "module": "exclusion config",
                        "controller": "exclusion_config",
                        "action": "exclusion-config",
                        "parent": 2,
                        "order_num": 3,
                        "show_in_menu": 1,
                        "created_at": "2023-07-05T11:45:05.000Z",
                        "updated_at": "2023-07-05T11:45:05.000Z",
                        "childSubMenu": []
                    }
                ]
            }
        ];
        
        const visibleMenus = this.checkVisibleMenu(menuHeaders);
     
        return (
            <div className="container-fluid" >
                <div className="header-main">
                    <div className="logo">
                        <img src={logo_img} className="" alt='incentive' />
                    </div>
                    <div className="right-panel-naviganion menupanel">
                        <div className="nav">
                            <ul>
                            {
                                        visibleMenus && (visibleMenus).length
                                            ?
                                            (visibleMenus).map((el, i) => {

                                                return (<li className={el.childMenu.length && !el['action'] ? 'dropdownmenu' : ''} key={i}>

                                                    <NavLink to={this.linkUrl(el)} className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "active" : ""}>
                                                        {this.props.t('MENU.' + (el.name).replaceAll(' ','_'))}
                                                    </NavLink>

                                                    <ul className="submenu" key={i}>
                                                        {
                                                            this.checkVisibleMenu(el['childMenu']).map((elm, j) => {
                                                                let childMenuName = elm['name'] 
                                                                return (<li className="level2" key={j}>

                                                                    <NavLink to={this.linkUrl(elm)} className={this.getCurrentClass(elm)}>{childMenuName}</NavLink>
                                
                                                                </li>)
                                                            })
                                                        }
                                                    </ul>
                                                </li>)

                                            })
                                            :
                                            (
                                                <li></li>
                                            )
                                    } 
                            </ul>
                        </div> 

                        <div className="user-detail ">
                            <ul>

                                <li className="dropdownmenu">
                                    <NavLink to="#">
                                        <span className="userarc">
                                            {name.length && name[0].toUpperCase()}
                                        </span>
                                        <div className="username">
                                            <span>{name}</span>
                                            <span className="user-degintion">{role}</span>
                                        </div>
                                    </NavLink>
                                    <ul className="submenu">
                                        <li><NavLink to="#" onClick={this.logOutUser.bind()}>Log Out</NavLink> </li>
                                    </ul>
                                </li>

                            </ul>
                        </div>
 
                    </div>

                </div>

            </div >
        );
    }
}
export default withTranslation('common')(Navigation); 