import React, { useEffect, useState } from "react";  
import FullPageLoader from "../common/FullPageLoader";   
import MultiSelect from '../common/MultiSelect'

const SalesTeamExclusion = ({ setSelectedDealerRecord,selectedDealerRecord, salesHierarchy, loader, businessLine, salesList}) => {
  
    const [loading, setLoading] = useState(loader); 
    const [businessLines, setBusinessLines] = useState([]);  
    const [selectedUsers, setSelectedUsers] = useState(salesList || {}); 
    const [selectAllState, setSelectAllState] = useState({});

    useEffect(() => {
        if (salesHierarchy?.length) {
           let data =  salesHierarchy?.reduce((acc, sale) => { 
            const allSelected = sale?.users?.every((user) => selectedDealerRecord?.[sale.type]?.includes(user.value));
            acc[sale.type] = allSelected ? allSelected : false;
              return acc;
            }, {});
            setSelectAllState(data);

            let users = salesHierarchy?.reduce((acc, sale) => {
                acc[sale.type] = sale.users.map((user) => ({
                  ...user,
                  selected: selectedDealerRecord?.[sale.type]?.includes(user.value) ? true : false,
                }));
                return acc;
              }, {}); 
            setSelectedUsers(users); 
            setBusinessLines(selectedDealerRecord?.business_line || []);

        }
    }, [salesHierarchy]);
    
      const handleSelectAll = (roleId, isChecked) => {
        let roleList = selectAllState;
        let usersList = selectedUsers;
        if (usersList[roleId]) {
            usersList[roleId] = usersList[roleId].map((user) => ({
              ...user,
              selected: isChecked,
            }));
        }  
        roleList[roleId] = isChecked;  
        const uniqueBL = setBusinessLineForSelectedUser(usersList);
         
        setSelectedDealerRecord({ 
            ...selectedDealerRecord,
            [roleId]: usersList[roleId]?.filter(user => user.selected)?.map(user => user.value),
            business_line : uniqueBL
        }); 
        setBusinessLines(uniqueBL);
        setSelectedUsers(usersList);
        setSelectAllState(roleList);
  
      };
    
      const handleUserSelection = (roleId, userIndex, isChecked) => { 
        
        let usersList = selectedUsers;
        if (usersList[roleId]) {
            usersList[roleId][userIndex]["selected"] = isChecked;
        }  
        const uniqueBL = setBusinessLineForSelectedUser(usersList);
        const allSelected = usersList[roleId]?.every((user) => user.selected);

        setSelectAllState((prev) => ({ ...prev, [roleId]: allSelected })); 
        setSelectedDealerRecord({ 
            ...selectedDealerRecord,
            [roleId]: usersList[roleId]?.filter(user => user.selected)?.map(user => user.value),
            business_line : uniqueBL
        }); 
        setBusinessLines(uniqueBL);
        setSelectedUsers(usersList); 
 
      }; 
  
   
  
    const handleBusinessLine = async (e, list) => {  
        let getSelectedBusinesLine = businessLines;
        if (e.target.checked) { 
            getSelectedBusinesLine = [...getSelectedBusinesLine, list.value]
            setBusinessLines(getSelectedBusinesLine);
            
        } else { 
            let newSelctedId = businessLines.filter((ob) => ob !== list.value); 
            getSelectedBusinesLine = newSelctedId;
            setBusinessLines(getSelectedBusinesLine); 
        }   

        let usersList = selectedUsers; 
        let roleList = selectAllState;
        let updatedDealerRecord = selectedDealerRecord
        Object.keys(usersList).forEach(roleId => {
            usersList[roleId] = usersList[roleId].map(user => { 
              if (user.business_line === list.value) {
                return { ...user, selected: e.target.checked }; 
              }
              return user;  
            }); 
            roleList[roleId]  = usersList[roleId]?.every((user) => user.selected); 
            updatedDealerRecord[roleId] = usersList[roleId]?.filter(user => user.selected)?.map(user => user.value);
        });
 
        setSelectedUsers(usersList);
        setSelectAllState(roleList);
        setSelectedDealerRecord({  
            ...updatedDealerRecord,
            business_line: getSelectedBusinesLine
        }); 
    }; 
 
 

    const handleChange = (roleId, value) => {  
        let usersList = selectedUsers;
        let checkedUser = value?.map(e => e.value);
        if (usersList[roleId]) {
            usersList[roleId] = usersList[roleId].map((user) => (
            {
              ...user,
              selected: checkedUser?.includes(user.value),
            }));
        }   
        const uniqueBL = setBusinessLineForSelectedUser(usersList);
        const allSelected = usersList[roleId]?.every((user) => user.selected);

        setSelectAllState((prev) => ({ ...prev, [roleId]: allSelected })); 
        setSelectedDealerRecord({ 
            ...selectedDealerRecord,
            [roleId]: usersList[roleId]?.filter(user => user.selected)?.map(user => user.value),
            business_line : uniqueBL
        });
        setBusinessLines(uniqueBL);  
        setSelectedUsers(usersList);
  
    }

    const setBusinessLineForSelectedUser = (usersList) => { 
        const allUsers = Object.values(usersList).flat()?.flat(); 
        const appType = allUsers?.filter(user => user.selected && user.business_line !== null)?.map(user => user.business_line);
        const uniqueBusinessLines = [...new Set([...appType])];  
        return uniqueBusinessLines;
    }
     
    return (
        <>  
            <FullPageLoader show={loading} />
            <div className={"data-table sales-table-view"}>
                <table>
                    <thead>
                        <tr id={1}> 
                            <th className={'exclusion-business-line headcol'}>Business Line</th>
                            <th className={'exclusion-sales-heading'}> <span>Sales Hierarchy</span>
                                <table className={'sales-hierarchy-sub-table'}>
                                    <thead>
                                    <tr >
                                            {salesHierarchy?.map((sale) => (
                                                <th key={sale?.type}>
                                                    <div className="custom-control custom-checkbox sales-headers">
                                                        <input
                                                            type="checkbox"
                                                            id={`selectAll-${sale.name}`}
                                                            className="custom-control-input"   
                                                            checked={selectAllState?.[sale.type]}
                                                            onChange={(e) =>
                                                              handleSelectAll(sale.type, e.target.checked)
                                                            }
                                                            disabled={sale.users.length === 0}
                                                        />
                                                        
                                                        <label
                                                            htmlFor={`selectAll-${sale.name}`}
                                                            className="custom-control-label"
                                                        > 
                                                        </label>
                                                        {sale?.name?.toUpperCase()}
                                                    </div>
                                                </th>
                                            ))}
                                        </tr>
                                    </thead>
                                </table>
                            </th>
                        </tr>
                    </thead>
                    <tbody> 
                        <tr>
                            <td className={'headcol bg-white'}></td>
                            <td className={'selected-hierarchy-users'}>
                                <table className={'sales-hierarchy-sub-table'}>
                                    <tbody> 
                                        <tr className=''>
                                            {salesHierarchy?.map((sale) => (
                                                <td key={`selected-${sale.name}`}>
                                                    {selectedUsers?.[sale.type]?.filter((user) => user.selected)?.length || 0}{" "}
                                                    Selected
                                                </td>
                                            ))}
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                          
                           
                        </tr>
                        <tr>
                            <td className={'headcol bg-white'}> 
                                {businessLine?.map((line, index) => (
                                    <div className="custom-control custom-checkbox" key={index}>
                                        <input
                                            id={`businessLine-${index}`}
                                            type="checkbox"
                                            className="custom-control-input"  
                                            onChange={(e) => handleBusinessLine(e, line )}
                                            checked={ selectedDealerRecord?.["business_line"]?.includes(line.value) || false}
                                        />
                                        <label
                                            htmlFor={`businessLine-${index}`}
                                            className="custom-control-label"
                                        > 
                                        </label>
                                        {line.label}
                                    </div>
                                ))} 
                            </td>

                            <td>
                                <table>
                                    <tbody>
                                        <tr>
                                            {salesHierarchy?.map((sale) => (
                                                <td key={`multiselect-${sale.name}`}>
                                                    <fieldset className="single-select">
                                                        <div className="material">
                                                            <MultiSelect
                                                                isMulti={true}
                                                                options={sale.users}
                                                                value={sale.users?.filter((e) => selectedDealerRecord[sale.type]?.includes(e.value))}
                                                                placeholder="Select"
                                                                className="react-select"
                                                                classNamePrefix="react-select"
                                                                onChange={(e) => handleChange(sale.type, e)}
                                                            />
                                                        </div>
                                                    </fieldset>
                                                </td>
                                            ))}
                                        </tr>
 
                                        <tr>
                                            {salesHierarchy?.map((sale) => (
                                                <td key={`users-${sale.type}`}>
                                                    {sale.users?.map((user, index) => (
                                                        <div key={`${sale.name}-${index}`} className="custom-control custom-checkbox">
                                                            <input
                                                                id={`user-${sale.name}-${index}`}
                                                                type="checkbox"
                                                                className="custom-control-input"
                                                             

                                                                onChange={(e) =>
                                                                handleUserSelection(sale.type, index, e.target.checked )}
                                                                  checked={selectedUsers?.[sale.type]?.[index]?.selected || false}
                                                                  disabled={false}
                                                            />
                                                            <label
                                                                htmlFor={`user-${sale.name}-${index}`}
                                                                className="custom-control-label"
                                                            >
                                                                {user?.label}
                                                            </label>
                                                        </div>
                                                    ))}
                                                </td>
                                            ))}
                                        </tr>

                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        
                     
                    </tbody>
                </table>
            </div>

        </>
    );
};

export default SalesTeamExclusion;
