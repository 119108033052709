 import React, { useEffect, useState } from "react"; 
import FullPageLoader from "../common/FullPageLoader";
import MultiSelect from '../common/MultiSelect' 

const DealerExclusion = ({dealerExclusion, dealerCategory, dealerData, setSelectedDealerRecord,selectedDealerRecord, loader}) => {
    
    const [allCategory, setAllCategory] = useState(false);
    const [allDealers, setAllDealers] = useState(false);
    const [loading, setLoading] = useState(loader);  
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [selectedDealers, setSelectedDealers] = useState([]);

    const [selectedCategoryDealer, setSelectedCategoryDealer] = useState({})
    // const [categoryOptions, setCategoryOptions] = useState([]);
    // const [dealerOptions, setDealerOptions] = useState([]);
    
   
    useEffect(() => { 
       if(dealerCategory?.length == selectedDealerRecord?.dealer_category_id?.length){
         setAllCategory(true);
       }

       if(dealerData?.length == selectedDealerRecord?.dealer_id?.length){
        setAllDealers(true);
       }
       setSelectedCategories(selectedDealerRecord?.dealer_category_id || []);
       setSelectedDealers(selectedDealerRecord?.dealer_id || []);

     

    },[selectedDealerRecord, dealerExclusion]);

    const selectAll = (e) => {
        let selectedId = [];
        let dealerIds = [];
        let dealerCatData = {}
        if (dealerCategory.length) {
            selectedId = dealerCategory.reduce((acc, cat) => {
                return acc.concat(cat.id);
            }, []);
            dealerIds = dealerData.reduce((acc, dealer) => {
                return acc.concat(dealer.dealer_id);
            }, []);
 
        }
 

        setAllCategory(e.target.checked);
        setAllDealers(e.target.checked);
        if (e.target.checked) {
            dealerCatData = dealerCategory.reduce((acc, { id, dealer_ids }) => {
                const filtered = dealer_ids.filter(id => dealerIds.includes(id));
                if (filtered.length) acc[id] = filtered;
                return acc;
            }, {});

            setSelectedCategories(selectedId);
            setSelectedDealers(dealerIds);
            setSelectedDealerRecord({
                ...selectedDealerRecord,
                dealer_id: dealerIds,
                dealer_category_id : selectedId
            }); 
            setSelectedCategoryDealer(dealerCatData);  
        } else {
            setSelectedCategories([]);
            setSelectedDealers([]);
            setSelectedDealerRecord({
                ...selectedDealerRecord,
                dealer_id: [],
                dealer_category_id : []
            });
            setSelectedCategoryDealer({});  
        }
    };


    const handleCategorySelection = async (e, list) => {
        let getSelectedCategory = selectedCategories;
        let dealerIds = selectedDealers;
        let dealerCatData = selectedCategoryDealer;
       
        if (e.target.checked) { 
            getSelectedCategory = [...getSelectedCategory, list.id];
            dealerIds = [...dealerIds, ...list.dealer_ids];
            dealerCatData = {...dealerCatData, [list.id] : list.dealer_ids}
            
            if(getSelectedCategory?.length == dealerCategory?.length){
                setAllCategory(true); 
            }

            if(dealerIds?.length == dealerData?.length){
                setAllDealers(true);
            }
            setSelectedCategoryDealer(dealerCatData);  
            setSelectedDealers(dealerIds);
            setSelectedCategories(getSelectedCategory);
            setSelectedDealerRecord({
                ...selectedDealerRecord, 
                dealer_id: dealerIds,
                dealer_category_id : getSelectedCategory
            }); 
        } else { 
            setAllCategory(false);
            setAllDealers(false);
            let newSelctedId = selectedCategories?.filter((ob) => +ob !== +list.id);
            getSelectedCategory = newSelctedId;
            dealerIds = dealerIds.filter(id => !list.dealer_ids.includes(id))
          
            setSelectedDealers(dealerIds);
            setSelectedCategories(getSelectedCategory);
            setSelectedDealerRecord({
                ...selectedDealerRecord, 
                dealer_id: dealerIds,
                dealer_category_id : getSelectedCategory
            });
            
            if(dealerCatData[list.id]?.length){ 
                delete dealerCatData[list.id]; 
                setSelectedCategoryDealer(dealerCatData);
            }
        }

    };

    const handleDealerSelection = async (e, list) => {
        let getSelectedCategory = selectedCategories;
        let dealerIds = selectedDealers;
        let dealerCatData = selectedCategoryDealer;
        
        if (e.target.checked) {
         
            if(!getSelectedCategory.includes(list.category_id)){
                getSelectedCategory = [...getSelectedCategory, list.category_id];  
            }
           
            dealerIds = [...dealerIds, list.dealer_id]; 
            if(Object.keys(dealerCatData).includes(String(list.category_id))){ 
                dealerCatData[list.category_id] = [...dealerCatData[list.category_id], list.dealer_id];
            } else {
                dealerCatData[list.category_id] = [list.dealer_id]
            }  
  
            setSelectedCategoryDealer(dealerCatData);
            setSelectedDealers(dealerIds); 
            setSelectedCategories(getSelectedCategory);
            setSelectedDealerRecord({
                ...selectedDealerRecord, 
                dealer_id: dealerIds,
                dealer_category_id : getSelectedCategory
            }); 
        } else { 
            let newSelctedId = selectedDealers.filter((ob) => +ob !== +list.dealer_id);
            dealerIds = newSelctedId;
            dealerIds = dealerIds.filter(id => id != list.dealer_id);
            let selectedCategory = dealerCategory?.find(cat => cat.id == list.category_id)
            
            const DeselectCategory = selectedCategory?.dealer_ids?.every((id) => !dealerIds?.includes(id));
            getSelectedCategory = DeselectCategory ? getSelectedCategory?.filter((cat) => cat != list.category_id) : getSelectedCategory;
          
            if(DeselectCategory){ 
                delete dealerCatData[list.category_id]; 
            } else {
                dealerCatData[list.category_id] = dealerCatData[list.category_id]?.filter(id => id != list.dealer_id)
            }   
            
            setSelectedCategoryDealer(dealerCatData);
            setSelectedDealers(dealerIds);
            setSelectedCategories(getSelectedCategory); 
            setSelectedDealerRecord({
                ...selectedDealerRecord, 
                dealer_id: dealerIds,
                dealer_category_id : getSelectedCategory
            }); 
        }

        setAllDealers(dealerIds?.length == dealerData?.length);
        setAllCategory(getSelectedCategory?.length == dealerCategory?.length); 

    };


    const handleChange = (value, type) => {  
        let dealerIds = [];
        let categoryIds = [];
        let checkedOptions = value?.map(e => e.value);
        let dealerCatData = selectedCategoryDealer;  
        if(type === "category"){ 
            Object.keys(dealerCatData).forEach((key) => { 
                if (!checkedOptions.includes(+key)) {
                  delete dealerCatData[key];
                }
            });
            value.forEach(cat => { 
                if(!Object.keys(dealerCatData).includes(String(cat.value))){
                    dealerCatData[cat.value] = [cat.dealer_ids]
                }
            });

            categoryIds = Object.keys(dealerCatData).map(Number);  
            dealerIds= Object.values(dealerCatData).flat()?.flat(); 
            
            setSelectedDealerRecord({
                ...selectedDealerRecord, 
                dealer_id: dealerIds,
                dealer_category_id : categoryIds
            }); 
            setSelectedCategoryDealer(dealerCatData);
            setSelectedDealers(dealerIds);
            setSelectedCategories(categoryIds); 
 
        } else { 
            dealerCatData = value.reduce((acc, { category_id, value }) => { 
                if (!acc[category_id]) {
                    acc[category_id] = [];
                } 
                acc[category_id].push(value);
                return acc;
            }, {});

            categoryIds = Object.keys(dealerCatData).map(Number);  
            dealerIds= Object.values(dealerCatData).flat()?.flat(); 
            
            setSelectedDealerRecord({
                ...selectedDealerRecord, 
                dealer_id: dealerIds,
                dealer_category_id : categoryIds
            }); 
            setSelectedCategoryDealer(dealerCatData);
            setSelectedDealers(dealerIds);
            setSelectedCategories(categoryIds); 
         
        } 
        setAllCategory(categoryIds?.length == dealerCategory?.length);
        setAllDealers(dealerIds?.length == dealerData?.length); 
  
    }
 
    
 
    const categoryOptions = dealerCategory?.map(category => ({
        label: category.title,  
        value: category.id ,
        dealer_ids : category.dealer_ids  
       }));
 

    const dealerOptions = dealerData?.map(dealer => ({
        label: dealer.organization + "(GCD" + dealer.dealer_id + ")",  
        value: dealer.dealer_id,
        category_id : dealer.category_id      
       })); 
 
 
     return (
        <>
            <FullPageLoader show={loading} />
            <div className={"data-table "}>
                <table className="dealer-exclusion-table">
                    <thead>
                        <tr> 
                            <th>
                                <div className="custom-control custom-checkbox">
                                    <input
                                        type="checkbox"
                                        id="selectAllCategories"
                                        className="custom-control-input"
                                        onChange={(e) => selectAll(e, 'category')}
                                        checked={allCategory}
                                    />
                                    <label
                                        htmlFor="selectAllCategories"
                                        className="custom-control-label"
                                    >
                                        Dealership Categories
                                    </label>
                                </div>
                            </th>
                            <th>
                                <div className="custom-control custom-checkbox">
                                    <input
                                        type="checkbox"
                                        id="selectAllDealers"
                                        className="custom-control-input"
                                        onChange={(e) => selectAll(e, 'dealers')}
                                        checked={allDealers}
                                    />
                                    <label
                                        htmlFor="selectAllDealers"
                                        className="custom-control-label"
                                    >
                                        By Dealer & GCD Code
                                    </label>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody> 
                        <tr className={'dealer-selected-exclusion'}>
                            <td>{selectedCategories?.length} Selected</td>
                            <td>{selectedDealers?.length} Selected</td>
                        </tr>
  

                        <tr>
                            <td> 
                                <fieldset className="single-select">
                                     <div className="material">
                                         <MultiSelect
                                             isMulti={true}
                                             options={categoryOptions}
                                             value={categoryOptions?.filter((e) => selectedDealerRecord?.dealer_category_id?.includes(e.value))}                                             placeholder="Select"
                                             className="react-select"
                                             classNamePrefix="react-select"
                                             isSearchable={true}
                                             onChange={(e) => 
                                                handleChange(e, "category") 
                                            }
                                         />
                                     </div>
                                 </fieldset>
                            </td>
                            <td> 
                              <fieldset className="single-select">
                                     <div className="material">
                                         <MultiSelect
                                             isMulti={true}
                                             options={dealerOptions}
                                             value={dealerOptions?.filter((e) => selectedDealerRecord?.dealer_id?.includes(e.value))} 
                                             placeholder="By Dealership Name or GCD"
                                             className="react-select"
                                             classNamePrefix="react-select"
                                             isSearchable={true}
                                             onChange={(e) => 
                                                handleChange(e, "dealer") 
                                            }
                                         />
                                     </div>
                                 </fieldset>
                            </td>
                        </tr>
 
                        <tr>
                            <td>
                                {dealerExclusion?.dealer_categories?.map((category, index) => (
                                    <div
                                        key={`category-${index}`}
                                        className="custom-control custom-checkbox"
                                    >
                                        <input
                                            id={`category-${index}`}
                                            type="checkbox"
                                            className="custom-control-input"
                                            onChange={(e) => handleCategorySelection(e, category)}
                                            checked={selectedDealerRecord?.dealer_category_id?.includes(category.id)}
                                        />
                                        <label
                                            htmlFor={`category-${index}`}
                                            className="custom-control-label"
                                        >
                                            {category.title}
                                        </label>
                                    </div>
                                ))}
                            </td>
 
                            <td>
                                {dealerExclusion?.dealer_gcd_code?.map((dealer, index) => (
                                    <div
                                        key={`dealer-${index}`}
                                        className="custom-control custom-checkbox"
                                    >
                                        <input
                                            id={`dealer-${index}`}
                                            type="checkbox"
                                            className="custom-control-input"
                                            onChange={(e) => handleDealerSelection(e, dealer)}
                                            checked={selectedDealerRecord?.dealer_id?.includes(dealer.dealer_id)}
                                        />
                                        <label
                                            htmlFor={`dealer-${index}`}
                                            className="custom-control-label"
                                        >
                                            {dealer.organization}
                                        </label>
                                    </div>
                                ))}
                            </td>
                        </tr>
                    </tbody>
                </table>

            </div>
 

        </>
    );
};

export default DealerExclusion;
