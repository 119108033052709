 import Login from "../view/auth/Login" 
import NotFoundPage from "../view/common/404Page";
import { useRoutes } from "react-router-dom";
import Dashboard from "../view/dashboard/Dashboard";
// import DealerList from "../view/dashboard/common/DealerList"; 
import DealerList from "../view/dealer-incentive/DealerList";
import DealerConfiguration from "../view/dashboard/common/DealerConfiguration";
import ExclusionConfiguration from "../view/exclusion-config/ExclusionConfiguration";

 
function AppRoutes() {
	let Router = useRoutes([ 
		{ path: "/", element: <Dashboard /> },
		{ path: "/login", element: <Login /> },  
		{ path: "/dashboard", element: <Dashboard /> },
		{ path: "/dealer-list", element : <DealerList/>},
		{ path: "/dealer-config", element : <DealerConfiguration/>},
		{ path: "/exclusion-config", element : <ExclusionConfiguration/>},
		{ path: "*", element: <NotFoundPage /> },
	  ]);
	return Router;
}


  export default AppRoutes;
