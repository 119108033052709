import React, { useEffect,useState } from 'react';
import { useApolloClient } from '@apollo/client';  
import { Tab, Nav } from "react-bootstrap"; 
import { executeGraphQLQuery, executeGraphQLMutation } from "../../../src/common/executeGraphQLQuery"; 
import { GET_EXCLUSION_LIST, SAVE_EXCLUSION_DATA, GET_EXCLUSION_DATA } from "../../services/exclusiondata.gql";  
import FullPageLoader from '../common/FullPageLoader'; 
import DealerExclusion from "./DealerExclusion";
import SalesTeamExclusion from "./SalesTeamExclusion";
import { toast } from 'react-toastify';

const ExclusionConfiguration = () => { 
    const [exclusionAll, setExclusionAll] = useState({}); 
    const [activeTab, setActiveTab] = useState(1);  
    const [loading, setLoading] = useState(false); 
    const client = useApolloClient();  
    const [selectedDealerRecord,setSelectedDealerRecord] = useState({})
    const [salesList, setSalesList] = useState({});
    const [dealerList, setDealerList] = useState({});


    useEffect(() => { 
        if(!Object.keys(exclusionAll).length){
            getExclusionSavedData();
            
        } 
 
    },[exclusionAll]);
    

    const changeActiveTab =(activetab) => {
        setActiveTab(activetab); 
    }
 

    const getExclusionList = async() => {
        setLoading(true);
        executeGraphQLQuery(GET_EXCLUSION_LIST(), client).then(response => { 
            setLoading(false);
            // getExclusionSavedData();  
            if(response && response?.data && response.data?.exclusion_list) {
                let result = response.data.exclusion_list;  
                setExclusionAll(result);  
            } 
        }).catch((error) => {
            setLoading(false); 
        });
    };

    const getExclusionSavedData = async() => {
        setLoading(true);
        executeGraphQLQuery(GET_EXCLUSION_DATA(), client).then(response => { 
            setLoading(false); 
            getExclusionList();
            if(response && response?.data && response.data?.exclusion_data) {
                let result = response.data.exclusion_data;  
                setSalesList(result?.sales_list || {});
                setDealerList(result?.dealer_list || {}); 
                let setData = {
                    ...result.sales_list, ...result.dealer_list, type : 1
                } 
                setSelectedDealerRecord(setData);
            
            }
        }).catch((error) => {
            setLoading(false); 
        });
    };


    const formatInput = () => {
        let input = {
            "type": activeTab, 
            "status": 1,
          
        }; 

        if(activeTab == 1){
            input = {...input,   
                "agent_id" : selectedDealerRecord?.agent_id ? selectedDealerRecord?.agent_id.join(',') : null,
                "aro_id" : selectedDealerRecord?.aro_id ? selectedDealerRecord?.aro_id.join(',') : null,
                "so_id" : selectedDealerRecord?.so_id ? selectedDealerRecord?.so_id.join(',') : null,
                "sm_id" : selectedDealerRecord?.sm_id ? selectedDealerRecord?.sm_id.join(',') : null,
                "th_id" : selectedDealerRecord?.th_id ? selectedDealerRecord?.th_id.join(',') : null,
                "nsh_id" : selectedDealerRecord?.nsh_id ? selectedDealerRecord?.nsh_id.join(',') : null,
                "president_id" : selectedDealerRecord?.president_id ? selectedDealerRecord?.president_id.join(',') : null, 
                "va_id" : selectedDealerRecord?.va_id ? selectedDealerRecord?.va_id.join(',') : null,
                "business_line" : selectedDealerRecord?.business_line ? selectedDealerRecord?.business_line.join(',') : null
            }
        } else {
            input = {...input,   
                "dealer_category_id" : selectedDealerRecord?.dealer_category_id ? selectedDealerRecord?.dealer_category_id.join(',') : null,
                "dealer_id" : selectedDealerRecord?.dealer_id ? selectedDealerRecord?.dealer_id.join(',') : null
            }
        }
 
        return input;
        
    }


    const handleSubmit = async (e) => {
        e.preventDefault(); 
            if (true) {
                setLoading(true);
                let params = formatInput(); 
                let incentiveparams= {
                    "exclusion_input": params
                  }
 
                executeGraphQLMutation(SAVE_EXCLUSION_DATA,incentiveparams, client).then(response => { 
                    setLoading(false); 
                    if(response && response.data && response?.data?.save_exclusion && !response?.data?.save_exclusion?.is_error) {
                        toast.success(response.data.save_exclusion.message);
                        getExclusionList();
                        getExclusionSavedData();
                    }else{
                        toast.error(response.data.save_exclusion.message)
                    }
                }).catch((error) => {
                    setLoading(false);
                });
            }  
           
    }
 
  
   

    return (
        <div className="incentive-configuration">
            <FullPageLoader show={loading} />
            <div className="dealer-outer">
                <div className="container-fluid">
                    <div className="top-heading">
                        <h1>Exclusion</h1>
                    </div>

                    <div className="dealer-configuration-outer">
                        <Tab.Container id="left-tabs-example" defaultActiveKey={'1'} activeTab={activeTab} >
                            {/* MAIN HEADING */}
                            <Nav variant="pills" className="flex-column tab-line dealer-configuration-tab">
                                <div className="tab-list">
                                    <Nav.Item onClick={() =>{changeActiveTab(1)}}>
                                        <Nav.Link eventKey="1">Sales Team Exclusion</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item onClick={() => {changeActiveTab(2)}}>
                                        <Nav.Link eventKey="2">Dealer Exclusion</Nav.Link>
                                    </Nav.Item> 
                                </div>

                                <div className='btn-line-auto-width'> 
                                <button className="btn-line btn-auto-width" onClick={(e) => {handleSubmit(e)}}>Submit Details</button> 
                                </div>
 

                            </Nav>
                              
                            <Tab.Content key={activeTab} id={activeTab}>
                                <Tab.Pane eventKey={activeTab}> 
                                    <div className='payout-summary-tabs'>
                                        <Tab.Container id="left-tabs-example">
                                            {activeTab == 1 && <SalesTeamExclusion salesHierarchy={exclusionAll.sales_team_exclusion} businessLine ={exclusionAll.business_line} setSelectedDealerRecord={setSelectedDealerRecord} selectedDealerRecord={selectedDealerRecord} loader={loading} salesList={salesList}/>}
                                            {activeTab == 2 && <DealerExclusion dealerExclusion = {exclusionAll.dealer_exclusion} dealerCategory={exclusionAll?.dealer_exclusion?.dealer_categories} dealerData = {exclusionAll?.dealer_exclusion?.dealer_gcd_code} setSelectedDealerRecord={setSelectedDealerRecord} selectedDealerRecord={selectedDealerRecord} loader={loading} dealerList={dealerList}/>}
                                        </Tab.Container>
                                    </div>
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                
                 
                    </div>
                </div>
            </div>
   
        </div>

    );
};



export default ExclusionConfiguration;
