import { toast } from "react-toastify";
import PdfUploader from "../dashboard/common/PdfUploader";
import { Tab, Nav } from "react-bootstrap";
import React, { act, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {useDispatch } from "react-redux";
import { useApolloClient } from '@apollo/client'; 
import ViewTable from "./ViewTable"; 
import DealerListFilter from "./DealerListFilter";
import FullPageLoader from "../common/FullPageLoader";
import ConfirmationModal from "../common/ConfirmationModal";
import { executeGraphQLMutation } from "../../common/executeGraphQLQuery";
import { UPDATE_STATUS } from "../../services/dealerdata.gql";
import { getDealerData } from "../../store/action/allAction";  
import dealersIcon from "../../webroot/images/dealers.svg";
import monthViewIcon from "../../webroot/images/month_view.svg"; 
import Modal from '../common/Modal'
import ExportAllData from './ExportAllData'

const DealerList = (props) => {
    const [selectedDealerIdRecord,setSelectedDealerRecord] = useState({})
    const [activeTab, setActiveTab] = useState(1);
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState({});
    const [data, setData] = useState(null); 
    const [btnEnable, setBtnEnable] = useState(false);
    const [isDownloadReady, setIsDownloadReady] = useState(false);
    const [showApproveReject, setShowApproveReject] = useState(false);
    const [confimationPopUp, setConfirmationPopup] = useState({ status: false, msg: '' }); 
    const [removeSelectAll, setRemoveSelectAll] = useState(false);
    const [selectAll, setSelectAll] = useState(false);
    const client = useApolloClient();
    const { t } = useTranslation("common");
    const dispatch = useDispatch()
    const USER_DATA = JSON.parse(localStorage.getItem("user_information")); 
    const [showModel, setModal] = useState(false);
 

    const changeActiveTab =(activetab) => {
        setActiveTab(activetab);
        setShowApproveReject(false);
        setFilters({})
    }

    /**
     * function to call api for fetching data
     * @param {*} params filters for api
     * @param {*} type defines for what purpose data is being fetched
     * @returns result of api call
     */
    const getDataListing = async (params, type) => {
        setLoading(true);
        setData([]);
        let updatedFilters = {}
        //when reseting
        if (type !== 'reset') {
            updatedFilters['filter_list'] = { ...filters }
        }else{
            setShowApproveReject(false);
            setRemoveSelectAll(true);
        }

        updatedFilters['filter_list'] = { ...updatedFilters?.['filter_list'], ...params }
           
        const result = await dispatch(getDealerData(client, updatedFilters));
        setLoading(false);
        setData([])
        if(result?.data?.incentive_list?.userinfo?.length &&  !result?.data?.incentive_list?.is_error) {
            setBtnEnable(true)
            setData(result) //enable disable export btn
        } else{
            toast.error(result?.data?.incentive_list.message);
            setBtnEnable(false)
            setData([])
        } 
        setFilters(updatedFilters.filter_list)
        return result
    }
 
    const showModalConfimationPopUp = (params) => {
        let { status } = params 
        setConfirmationPopup(params)
        document.body.classList.remove("overflow-hidden");
        if (status) {
            document.body.classList.add("overflow-hidden");
        }
    }

    const showApproveRejectButton = (status) => {
        setShowApproveReject(status);
    }

    const confirmationModalResponse = (params) => {
        if(params.response){ 
            setLoading(true); 
            let approve_input= {
                "approve_input": { 
                    "month": selectedDealerIdRecord?.incentive_month,  
                    "parent_id": selectedDealerIdRecord?.dealer_ids?.join(","),
                    "status": confimationPopUp.status_id,
                    "created_by": USER_DATA?.id,
                    "select_all":selectAll,
                    "filters":filters

                }
            } 
            executeGraphQLMutation(UPDATE_STATUS,approve_input, client).then(response => { 
                setLoading(false);  
                if(response && response.data && response?.data?.approve_amount && !response?.data?.approve_amount?.is_error) { 
                    toast.success(response.data.approve_amount.message);
                }else{
                    toast.error(response.data.approve_amount.message)
                }
                getDataListing(filters);
            }).catch((error) => { setLoading(false); });   
        }
        setShowApproveReject(false);
        setRemoveSelectAll(true);
        showModalConfimationPopUp(params);
    }
    const parentFilter=(params)=>{
        let filterData={...params,...filters}
        setFilters(filterData);
        getDataListing(filterData);
    }

    /**
     * useEffect specifically to download csv
     */
    useEffect(() => {
        if (isDownloadReady) {
            document?.getElementById("csvDownloadLink")?.click();
            setIsDownloadReady(false)
        }
    }, [isDownloadReady]) //as soon as our csv data gets ready, export data by click

    // useEffect(() => {
    //     let updatedSelctedFilters = {};
    //     if (search) {
    //         updatedSelctedFilters['search'] = search;
    //     }
    //     if (status) {
    //         updatedSelctedFilters['status'] = [status];
    //     }
    //     setFilters({ ...updatedSelctedFilters })
       
    // }, [search,status])

    const showModal = () => {
        setModal(true)
        document.body.classList.add("overflow-hidden");
    }
    const closeModel = () => {
        setModal(false)
        document.body.classList.remove("overflow-hidden");
    } 

    return (
        <React.Fragment>
            <FullPageLoader show={loading} />

            <div className="dealer-outer">
                <div className="container-fluid">
                    <div className="top-heading bg-white dealer-incentive-top-heading minus-l-r-15">
                        <h1>Dealer Incentive</h1>
                    </div>

                    <div className="dealer-incentive-outer">
                        <Tab.Container id="left-tabs-example" defaultActiveKey={'1'} activeTab={activeTab} >
                            {/* MAIN HEADING */}
                            <Nav variant="pills" className="flex-column tab-line bg-white minus-l-r-15">
                                <div className="tab-list">
                                    <Nav.Item onClick={() => {changeActiveTab(1)}}>
                                        
                                        <Nav.Link eventKey="1">  
                                      
                                               <img src={monthViewIcon} className="iconView" alt='logo' /> Month View
                                            
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item onClick={() => {changeActiveTab(2)}}>
                                        <Nav.Link eventKey="2">  
                                           <img src={dealersIcon} className="" alt='logo' /> Dealer View 
                                      
                                       
                                        </Nav.Link>
                                    </Nav.Item> 
                                </div>
                                <div className="upload-eport-btn d-flex">
                                {
                                  showApproveReject ?
                                    <>
                                        <button className="btn-primary m-sm-r" onClick={() => showModalConfimationPopUp({ status: true, status_id: 2, msg: t("DEALER.APPROVE_MESSAGE")})}> <i className="ic-check"></i>Approve</button>
                                        <button className="btn-line m-sm-r reject-btn" onClick={() => showModalConfimationPopUp({ status: true, status_id: 5, msg: t("DEALER.REJECT_MESSAGE")})}> <i className="ic-clearclose"></i>Reject</button>
                                    </>:""  
                                }
                                <PdfUploader getDataListing={getDataListing} filters={filters}/> 
                                    <button className="btn-line  m-sm-l" disabled={!btnEnable} onClick={showModal}>
                                        <i className="ic-export"></i>
                                        Export Data
                                    </button> 

                                </div>

                            </Nav>

                            <Tab.Content key={activeTab} id={activeTab}>
                                <Tab.Pane eventKey={activeTab}>
                                    <div className="loan-filter-bg dashboard-filter-top m-lg-t m-lg-b">
                                        <DealerListFilter fetchListingData={getDataListing} setParentFilter={parentFilter} activeTab={activeTab}  />
                                    </div>
                                    <div className='payout-summary-tabs'>
                                        <Tab.Container id="left-tabs-example">
                                            <ViewTable setLoader={setLoading} fetchListingData={getDataListing} data={data} showApproveRejectButton={showApproveRejectButton} setSelectedDealerRecord={setSelectedDealerRecord} selectedDealerIdRecord={selectedDealerIdRecord} pageType={activeTab} removeSelectAll={removeSelectAll} setRemoveSelectAll={setRemoveSelectAll} setSelectAll={setSelectAll} />
                                            {/* <DealerListTable setLoader={setLoading} fetchListingData={getDataListing} data={data} showApproveRejectButton={showApproveRejectButton} setSelectedDealerRecord={setSelectedDealerRecord} selectedDealerIdRecord={selectedDealerIdRecord} pageType={activeTab} /> */}
                                        </Tab.Container>
                                    </div>
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </div>
                </div>
            </div>
            <ConfirmationModal confirmationData={{ show: confimationPopUp.status, confirmationText: confimationPopUp.msg }} confirmationModalResponse={confirmationModalResponse} />

            <div className="model-popup-outer confirmation-popup">
                <Modal show={showModel} handleClose={closeModel} >
                    <ExportAllData filters={filters} activeTab={activeTab}/> 
                </Modal>
            </div> 
        </React.Fragment>
    );
};

export default DealerList;
