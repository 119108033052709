import { gql } from '@apollo/client';


export const GET_EXCLUSION_LIST = () => {
    return gql
        `query {
    exclusion_list {
    sales_team_exclusion {
      role_id
      name
      type
      users {
        label
        value 
        business_line
      }
    } 
    dealer_exclusion {
      dealer_categories {
        id
        title
        dealer_ids
        code
      }
      dealer_gcd_code {
        organization
        dealer_id
        category_id
        category
      }
    }
    business_line {
      label
      value
    }
  }
}
`
}

export const SAVE_EXCLUSION_DATA = gql `mutation SaveExclusion($exclusion_input: ExclusionInput!) {
    save_exclusion(exclusion_input: $exclusion_input) {
        message
    }
}`



export const GET_EXCLUSION_DATA = () => gql`
query{
    exclusion_data {
      sales_list{
          type 
          business_line
          th_id
          nsh_id 
          sm_id 
          so_id
          president_id
          va_id
          aro_id
          agent_id 
      }
      dealer_list{
          type
          dealer_id
          dealer_category_id
      } 
  }
}
`;